<template>
    <div class="expandable-container relative mb-2 outline-none">
        <div @click="changeState" class="flex items-center p-2 rounded-lg cursor-pointer  hover:bg-gray-200 hover:bg-opacity-20 transition">
            <slot name="icon" />
            <div class="expandable flex w-full justify-between ml-3">
                <p class="focus:outline-none">
                    <slot name="label" />
                </p>
                <svg v-if="test" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
            </div>
        </div>
        <slot name="content" v-if="test || collapsed" />
    </div>
</template>
<script>
export default {
    props: {
        collapsed: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            test: false,
        };
    },

    methods: {
        changeState() {
            if (this.collapsed) return;
            this.test = !this.test;
        },
    },
};
</script>
<style>
.collapsed .expandable {
    display: none;
}
</style>
